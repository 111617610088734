@import 'src/assets/styles/mixin.scss';
@import 'src/assets/styles/variables.scss';

.container {
  position: relative;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
}

footer {
  position: relative;
  width: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
}

.splitScreen {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  touch-action: none;
}

.splitScreen.active {
  height: 100vh;
  overflow: hidden;
}

.splitScreen.inactive {
  overflow-y: auto;
}

.splitScreen.hoveredLeft .bannerRight {
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none;
}

.splitScreen.hoveredRight .bannerLeft {
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none;
}

.section {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  transition: flex 0.5s ease-in-out, filter 0.5s ease-in-out;
  position: relative;
  align-items: center;
  justify-content: center;

  filter: grayscale(0%);
  transition: flex 0.5s ease-in-out, filter 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.active {
    flex: 0.6;
    filter: grayscale(0%);
  }

  &.inactive {
    flex: 0.4;
    filter: grayscale(100%);
  }
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transition: opacity 0.6s ease-in-out;
  z-index: 2;
}

.thumbnail.hidden {
  opacity: 0;
}

.video {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  z-index: 1;
}

.video.visible {
  opacity: 1;
  z-index: 2;
}

.overlay {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 3;
}

.section.active .overlay,
.section:hover .overlay {
  opacity: 1;
}

.hoverButtonLeft {
  position: absolute;
  bottom: 29%;
  margin-left: 35%;
  z-index: 4;
}

.hoverButtonRight {
  position: absolute;
  bottom: 29%;
  margin-right: 35%;
  z-index: 4;
}

.hoverContentLeft {
  position: absolute;
  bottom: 10%;
  color: white;
  margin-bottom: 50px;
  text-align: left;
  border-radius: 10px;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  z-index: 4;

  h2 {
    @include textStyle(28px, 600);
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }

  p {
    @include textStyle(18px, 500);
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
}

.hoverContentRight {
  position: absolute;
  bottom: 10%;
  color: white;
  margin-bottom: 50px;
  text-align: right;
  border-radius: 10px;
  opacity: 0;  
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  z-index: 4;

  .contentRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .textContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 20px;
    }

    .madeInIcon {
      width: 130px;
      height: auto;
      margin-bottom: 5px;
    }
  }

  h2 {
    @include textStyle(28px, 600);
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }

  p {
    @include textStyle(16px, 500);
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
}

.hoverContentLeft.visible,
.hoverContentRight.visible {
  opacity: 1;
  transform: translateY(0);
  
  h2, p {
    opacity: 1;
    transform: translateY(0);
  }
}

.bannerLeft {
  position: absolute;
  bottom: 28%;
  margin-right: 40%;
  width: 250px;
  height: auto;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  z-index: 3;
}

.bannerRight {
  position: absolute;
  bottom: 28.5%;
  margin-left: 40%;
  width: 250px;
  height: auto;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  z-index: 3;
}

.button {
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.welcomeBanner {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 80px;
  height: auto;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  z-index: 5;
}

.welcomeBanner.hidden {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
  pointer-events: none;
}

.video {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}
